import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import ArdsleyTitle from "../components/ArdsleyTitle";

export const LocationPageTemplate = ({ image, content, title }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <ArdsleyTitle text={title} />
              <img
                align="right"
                width="40%"
                style={{
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
                src={
                  image.childImageSharp
                    ? image.childImageSharp.fluid.src
                    : image
                }
              ></img>

              <HTMLContent content={content} />

              <iframe
                frameborder="0"
                style={{ border: "0", width: '100%', height: '400px' }}
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBLPpYqrDULboAsqkLANTGnOLZ8OatDJwU
    &q=Historic Ardsley Apt Residences"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LocationPage = ({ data }) => {
  console.log("DATA", data);

  const {
    frontmatter: { image, title },
    html,
  } = data.markdownRemark;

  return (
    <Layout>
      <LocationPageTemplate image={image} content={html} title={title} />
    </Layout>
  );
};

LocationPage.propTypes = {
  data: PropTypes.object,
};

export default LocationPage;

export const locationPageQuery = graphql`
  query locationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
